import { Col, Row } from "antd";
import React from "react";
import tofi from "../../assets/tofi.png";
import raza from "../../assets/raza.png";
import mechx from "../../assets/mechx.png";
import iqra from "../../assets/iqra.png";
import industrial from "../../assets/industrial.svg";
import hanker from "../../assets/hanker.png";
import ftuk from "../../assets/ftuk.png";
import env from "../../assets/env.png";
import nine from "../../assets/9q.png";
import engine from "../../assets/engine.svg";
import thk from "../../assets/thk.png";
import tracker from "../../assets/tracker.png";
import battery from "../../assets/battery.jpeg";
import softtech from "../../assets/softtech.png";
import fireup from "../../assets/fireup.png";
import enexpert from "../../assets/enexpert.png";
import edmi from "../../assets/edmi.png";
import internetforshare from "../../assets/internetforshare.png";

function Brands() {
  return (
    <div className="parent_container">
      <div className="brands_container">
        <Row justify="center" align="middle">
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={tofi} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={raza} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={mechx} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={hanker} alt="brands" className="brand" />
          </Col>
          <Col className="image_container" md={12} xs={24}>
            <img src={industrial} alt="brands" className="img" />
          </Col>
          <Col className="image_container" md={12} xs={24}>
            <img src={iqra} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={thk} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={softtech} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={ftuk} alt="brands" className="img" />
          </Col>
          <Col className="image_container" lg={6} md={8} xs={12}>
            <img src={env} alt="brands" className="img" />
          </Col>
          <Col className="image_container" md={10} xs={24}>
            <img src={enexpert} alt="brands" className="brand" />
          </Col>
          <Col className="image_container" md={4} xs={12}>
            <img
              src={internetforshare}
              alt="brands"
              style={{ height: "150px" }}
              className='brand'
            />
          </Col>
          <Col className="image_container" md={10} xs={24}>
            <img src={tracker} alt="brands" className="brand" />
          </Col>
          {/* <Col className="image_container" md={8} sm={12} xs={24}>
            <img src={nine} alt="brands"  className="img"/>
          </Col> */}
          <Col className="image_container" md={8} sm={12} xs={24}>
            <img src={engine} alt="brands" className="brand" />
          </Col>
          {/* <Col className="image_container" md={8} sm={12} xs={24}>
            <img src={battery} alt="brands"  className="img"/>
          </Col> */}
          <Col className="image_container" md={8} xs={12}>
            <img src={fireup} alt="brands" className="img" />
          </Col>
          <Col className="image_container" md={8} xs={12}>
            <img src={edmi} alt="brands" className="img" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Brands;
